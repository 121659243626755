// This file was autogenerated. Please do not change.
export interface IGetPositionModeRequest {
    accountId: string;
    contractSymbol: string;
    placementTag: string;
}

export default class GetPositionModeRequest {
    readonly _accountId: string;

    /**
     * Example: A00000001
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _contractSymbol: string;

    /**
     * Example: BTC/USDT
     */
    get contractSymbol(): string {
        return this._contractSymbol;
    }

    readonly _placementTag: string;

    /**
     * Example: BYBIT_FUTURES
     */
    get placementTag(): string {
        return this._placementTag;
    }

    constructor(props: IGetPositionModeRequest) {
        this._accountId = props.accountId.trim();
        this._contractSymbol = props.contractSymbol.trim();
        this._placementTag = props.placementTag.trim();
    }

    serialize(): IGetPositionModeRequest {
        const data: IGetPositionModeRequest = {
            accountId: this._accountId,
            contractSymbol: this._contractSymbol,
            placementTag: this._placementTag,
        };
        return data;
    }

    toJSON(): IGetPositionModeRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            contractSymbol: typeof this._contractSymbol === 'string' && !!this._contractSymbol.trim(),
            placementTag: typeof this._placementTag === 'string' && !!this._placementTag.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
